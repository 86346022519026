<template>
  <div class="company mab">
    <div class="title">企业信息</div>
    <div class="content">
      <div class="flex">
        <div class="flex-1">
          <el-form-item
            label="企业名称："
            prop="lstEnterprise.0.entName"
            :rules="[{ required: true, message: '企业名称不能为空' }]"
          >
            <el-input v-model="enterprise[0].entName"></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item
              label="所属产业："
              prop="matterInfo.industry"
              :rules="[{ required: true, message: '所属产业不能为空' }]"
            >
              <el-select v-model="data.industry" placeholder="所属产业">
                <el-option label="石油装备" value="石油装备"></el-option>
                <el-option label="海工装备" value="海工装备"></el-option>
                <el-option label="精密铸造" value="精密铸造"></el-option>
                <el-option label="高端装备" value="高端装备"></el-option>
                <el-option label="生物医药" value="生物医药"></el-option>
                <el-option label="新能源" value="新能源"></el-option>
                <el-option label="低碳科技" value="低碳科技"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="data.industry == '其他'"
              label=""
              label-width="0"
              prop="matterInfo.industryDesc"
              :rules="[{ required: true, message: '所属产业不能为空' }]"
            >
              <el-input v-model="data.industryDesc"></el-input>
            </el-form-item>
          </div>
          <el-form-item
            label="我方陪同人员："
            :label-width="'180px'"
            :rules="[{ required: true, message: '我方陪同人员不能为空' }]"
            prop="matterInfo.receptionist"
          >
            <el-input v-model="data.receptionist"></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item
              label="线索渠道来源："
              prop="matterInfo.channelSource"
              label-width="180px"
              :rules="[{ required: true, message: '线索渠道不能为空' }]"
            >
              <div class="flex">
                <el-select v-model="data.channelSource" placeholder="线索渠道">
                  <el-option label="企业" value="企业"></el-option>
                  <el-option label="机构" value="机构"></el-option>
                  <el-option label="顾问" value="顾问"></el-option>
                  <el-option label="内部" value="内部"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label-width="0" prop="matterInfo.channelSourceDesc">
              <el-input v-model="data.channelSourceDesc"></el-input>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            label="地点："
            :rules="[{ required: true, message: '地点不能为空' }]"
            prop="matterInfo.address"
          >
            <el-input v-model="data.address"></el-input>
          </el-form-item>
          <el-form-item
            label="事项性质："
            prop="matterInfo.itemType"
            :label-width="'180px'"
            :rules="[{ required: true, message: '事项性质不能为空' }]"
          >
            <el-radio-group v-model="data.itemType">
              <el-radio :label="1">外出</el-radio>
              <el-radio :label="2">接待</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item
              label="时间："
              prop="projectInfo.contractTime"
              :rules="[{ required: true, message: '时间不能为空' }]"
            >
              <el-date-picker
                v-model="data.contractTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item> -->
          <el-form-item
            label="备注："
            :rules="[{ required: true, message: '备注不能为空' }]"
            prop="matterInfo.remark"
          >
            <el-radio-group v-model="data.remark">
              <el-radio :label="1">走出去</el-radio>
              <el-radio :label="2">请进来</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="对此事项评价：" prop="matterInfo.evaluation">
        <el-input type="textarea" v-model="data.evaluation"></el-input>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    enterprise: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.mab {
  margin-bottom: 30px;
}
.company {
  border: 1px solid #aab0be;
  .title {
    background: #f6f8fd;
    color: #374567;
    font-size: 14px;
    padding: 8px 24px;
    margin-bottom: 10px;
  }
  .content {
    padding: 16px 12px;
  }
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-select {
  margin-right: 10px;
}
.active-color {
  cursor: pointer;
}
.delete {
  text-align: center;
}
::v-deep .el-radio__inner {
  border-radius: 2px;
}
::v-deep .el-radio__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 0.006944rem solid #fff;
  border-left: 0;
  border-top: 0;
  height: 0.048611rem;
  left: 0.027778rem;
  position: absolute;
  top: 0.006944rem;
  background: transparent;
  border-radius: 0;
  -webkit-transform: rotate(45deg) scaleY(0);
  transform: rotate(45deg) scaleY(0);
  width: 0.020833rem;
  -webkit-transition: -webkit-transform 0.15s ease-in 0.05s;
  transition: -webkit-transform 0.15s ease-in 0.05s;
  transition: transform 0.15s ease-in 0.05s;
  transition: transform 0.15s ease-in 0.05s,
    -webkit-transform 0.15s ease-in 0.05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  -webkit-transform: rotate(45deg) scaleY(1);
  transform: rotate(45deg) scaleY(1);
}
</style>