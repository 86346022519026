<template>
  <div>
    <div class="border mab" v-for="(i, index) in data" :key="index">
      <div>
        <div class="title flex flex-between">
          <div>会议资料{{ index + 1 }}</div>
          <div
            class="active-color"
            v-if="index != 0"
            @click="handleRemove(false, index)"
          >
            删除
          </div>
        </div>
        <div class="content">
          <el-row>
            <el-col :span="11">
              <el-form-item
                class="flex-1"
                label-width="''"
                label="时间："
                :prop="'lstRecord.' + index + '.time'"
                :rules="[{ required: true, message: '时间不能为空' }]"
              >
                <div>
                  <el-date-picker
                    v-model="i.time"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="1">
              <el-form-item
                class="flex-1"
                label="外出/接待事由:"
                :prop="'lstRecord.' + index + '.reason'"
                label-width="''"
                :rules="[{ required: true, message: '外出/接待事由不能为空' }]"
              >
                <div class="el-date-editor--date">
                  <el-input
                    v-model="i.reason"
                    placeholder="一句话描述"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            v-for="(lab, i) in labels"
            :key="lab.id"
            :label="lab.name"
            :label-width="'100'"
            class="flex-1"
            :rules="[
              {
                required: lab.required ? true : false,
                message: `${lab.name}不能为空`,
              },
            ]"
          >
            <el-upload
              :action="url"
              :ref="`upload${i}`"
              :on-success="
                (response, file, fileList) =>
                  handleSuccess(file, lab.name, index)
              "
              :on-remove="
                (response, file, fileList) => handleRemove(response, index)
              "
              :before-upload="beforUpload"
              :file-list="dealData(index, lab.name)"
            >
              <div class="active-color border addBtn">添加附件</div>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="备注:"
            :prop="'lstRecord.' + index + '.remark'"
            :label-width="'60px'"
          >
            <el-input type="textarea" v-model="i.remark"></el-input>
          </el-form-item>
        </div>
      </div>
    </div>
    <div class="active-color border addBtn" @click="addAttachment">
      +添加外出/接待信息
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { getAttachment } from "@/api/crm/workbench";
export default {
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      url: URL.API_ATTACHMENT_UPLOAD,
      uploadNum: 0,
      labels: [
        // {
        //   name: "事件/访问函",
        //   id: 1,
        //   required: true,
        // },
        {
          name: "洽谈结果",
          id: 2,
          required: true,
        },
        {
          name: "外出/接待方案",
          id: 3,
          required: true,
        },
        {
          name: "现场照片",
          id: 4,
          required: true,
        },
        {
          name: "企业资料",
          id: 5,
        },
      ],
    };
  },
  watch: {
    uploadNum(newVal) {
      if (newVal == 0) {
        this.$emit("isClick", true);
      }
    },
  },
  methods: {
    dealData(i, name) {
      if (
        this.data[i].lstAttachment &&
        this.data[i].lstAttachment.length == 0
      ) {
        return [];
      }
      const files = this.data[i].lstAttachment.filter(
        (val) => val.docTypeName == name
      );
      files.forEach((val) => {
        val.name = val.originalName;
        val.url = URL.static + val.fileName;
      });
      return files;
    },
    addAttachment() {
      this.$emit("addAttachment", {
        name: "lstRecord",
        data: {
          time: "",
          lstAttachment: [],
        },
      });
    },
    clearFiles() {
      this.labels.forEach((val, index) => {
        let name = "upload" + index;
        this.$refs[name].clearFiles()
      });
    },
    handleRemove(file, index) {
      if (file) {
        const i = this.data[index].lstAttachment.findIndex(
          (x) => x.uid == file.uid
        );
        this.$emit("removeAttachment", {
          index: index,
          name: "lstRecord",
          i: i,
          child: "lstAttachment",
        });
      } else {
        this.$emit("removeAttachment", {
          i: index,
          name: "lstRecord",
        });
      }
    },
    beforUpload(file) {
      if (file.size > 1024 * 1024 * 100) {
        this.$message({
          message: "单个文件 " + file.name + " 不能大于100M",
          type: "warning",
        });
        return false;
      } else if (file.size <= 0) {
        this.$message({
          message: "文件 " + file.name + " 不能为空",
          type: "warning",
        });
        return false;
      }
      if (this.uploadNum == 0) {
        this.$emit("isClick", false);
      }
      this.uploadNum += 1;
      return true;
    },
    handleSuccess(file, value, index) {
      this.uploadNum--;
      getAttachment({ name: value }).then((res) => {
        if (res.status == 200) {
          this.$emit("addAttachment", {
            index: index,
            name: "lstRecord",
            child: "lstAttachment",
            data: {
              originalName: file.name,
              fileName: file.response.data,
              docTypeName: value,
              docTypeId: res.data,
              uid: file.uid,
              name: file.name,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.active-color {
  cursor: pointer;
}
.addBtn {
  height: 30px;
  line-height: 30px;
  margin-top: 0;
}
.title {
  background: #f6f8fd;
  color: #374567;
  font-size: 14px;
  padding: 8px 24px;
  margin-bottom: 10px;
}
.content {
  padding: 16px 12px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
::v-deep .el-date-editor--date .el-input__inner {
  border-color: #aab0be;
  height: 40px !important;
  border-radius: 5px;
  padding: 10px auto;
}
.mab {
  margin-bottom: 30px;
}
.el-input__inner {
}
</style>